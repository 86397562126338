<script lang="ts" setup>
import { toRef } from 'vue';

import type { CustomerConfigOddsType } from '@core/customer';

import OddsList from 'web/src/modules/sportline/submodules/odds/components/OddsList/OddsList.vue';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';

const oddsStore = useOddsStore();
const odds = toRef(oddsStore, 'oddsList');
const customerOdd = toRef(oddsStore, 'userOddTypeLocal');

function setOdd(oddKind: CustomerConfigOddsType): void {
  void oddsStore.setCustomerOdd(oddKind);
}
</script>

<template>
  <div v-auto-id="'OddsRoutePage'" :class="$style['odds-wrapper']">
    <OddsList
      :odd="customerOdd"
      :odds="odds"
      @click="setOdd($event)"
    />
  </div>
</template>

<style lang="scss" module>
.odds-wrapper {
  padding: 0 32px 32px;
}
</style>
